export var fonts = {
    book: {
        fontFamily: "HDI Pro, Arial, sans-serif",
        fontWeight: "normal",
    },
    medium: {
        fontFamily: "HDI Pro, Arial, sans-serif",
        fontWeight: 500,
    },
    bold: {
        fontFamily: "HDI Pro, Arial, sans-serif",
        fontWeight: "bold",
    },
};
