var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useRef, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { customTypography, typography } from "@basics/typography";
import { colors } from "@basics/colors";
import { rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import cn from "classnames";
import { useClickOutside } from "@hooks/useClickOutside";
import { FAIcon } from "@components/FAIcon/FAIcon";
import { Key } from "@basics/keys";
import { useCommonFormStyles } from "@components/Block/Forms/Elements/common";
var OPTION_ELEMENT_HEIGHT = 24 + 16 * 2 + 2;
var useStyles = createUseStyles({
    selectCustom: {
        zIndex: 10,
        position: "relative",
    },
    underlinePrimary: {
        backgroundColor: colors.webGreen40,
        bottom: -2,
        display: "inline-block",
        height: 2,
        left: 0,
        position: "absolute",
        transition: "all .3s ease-out",
        width: 0,
        zIndex: 20,
    },
    underlinePrimaryFocused: {
        width: "100%",
    },
    select: {
        position: "relative",
        borderBottom: "2px solid ".concat(colors.black),
        zIndex: 20,
        marginBottom: 0,
    },
    defaultText: customTypography(typography.textDefault, { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 }),
    selectNative: __assign({ background: colors.gray10, border: 0, borderBottom: "2px solid ".concat(colors.black), borderRadius: 0, color: colors.black, padding: "".concat(rem(spacings.xs), " ").concat(rem(spacings.s)), width: "100%", outline: "none", position: "absolute", top: 0, left: 0 }, customTypography(typography.textSmall, {
        marginBottom: 0,
    })),
    customTrigger: {
        height: "calc(100% - 2px)",
        padding: "".concat(rem(spacings.xs), " ").concat(rem(spacings.s), " !important"),
        borderTop: "2px solid ".concat(colors.gray10, " !important"),
        "& $customOptionLabel": {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
    },
    customOptionLabel: {},
    customOptionLabelMargin: {
        marginRight: rem(spacings.s),
    },
    customOptionsWrapper: {
        background: colors.gray10,
        border: 0,
        borderBottom: "2px solid ".concat(colors.black),
        borderRadius: 0,
        color: colors.black,
        minWidth: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        boxShadow: "0 0 4px #e9e1f8",
        zIndex: 30,
        display: "none",
        maxHeight: 5.5 * OPTION_ELEMENT_HEIGHT,
        overflowY: "auto",
        "&$isActive": {
            display: "block",
        },
    },
    customOption: __assign(__assign({ width: "100%", padding: rem(spacings.s), color: colors.gray60, fontWeight: "normal", backgroundColor: colors.gray10, borderTop: "2px solid ".concat(colors.gray30), textAlign: "left", alignItems: "center" }, customTypography(typography.textSmall, {
        marginBottom: 0,
    }, {
        marginBottom: 0,
    })), { "&$isActive": {
            color: colors.black,
        }, "&$isHover": {
            backgroundColor: colors.white,
        }, display: "flex" }),
    customOptionIconWrapper: {
        width: 20,
        lineHeight: 0,
    },
    customOptionPreIconWrapper: {
        marginRight: rem(spacings.s),
    },
    customOptionPostIconWrapper: {
        marginLeft: "auto",
    },
    isActive: {},
    isHover: {},
    visuallyHidden: __assign({}, typography.visuallyHidden),
});
export function Select(props) {
    var selectedOptionIdx = props.selected
        ? props.options.findIndex(function (opt) { return opt.value === props.selected; })
        : -1;
    var selectedOption = selectedOptionIdx > -1 ? props.options[selectedOptionIdx] : undefined;
    var _a = useState(false), isOpen = _a[0], setOpen = _a[1];
    var _b = useState(false), wasFocused = _b[0], setWasFocused = _b[1];
    var _c = useState(false), isFocused = _c[0], setFocused = _c[1];
    var _d = useState(selectedOptionIdx), hoveredElementIdx = _d[0], setHoveredElementIdx = _d[1];
    var _e = useState(selectedOptionIdx > 0 ? selectedOptionIdx : 0), scrollTopElementIdx = _e[0], setScrollTopElementIdx = _e[1];
    var labelRef = useRef(null);
    var optionsRef = useRef(null);
    var styles = useStyles();
    var commonFormStyles = useCommonFormStyles();
    function openDropdown() {
        setOpen(true);
        setScrollTopElementIdx(0);
    }
    useClickOutside(labelRef, function () {
        var _a;
        setOpen(false);
        setFocused(false);
        wasFocused && ((_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props));
        setWasFocused(false);
    }, [wasFocused, props.onBlur]);
    useEffect(function () {
        var _a, _b;
        (_b = (_a = optionsRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo) === null || _b === void 0 ? void 0 : _b.call(_a, 0, scrollTopElementIdx * OPTION_ELEMENT_HEIGHT);
    }, [isOpen, scrollTopElementIdx, optionsRef.current]);
    function scrollUp(hovElementIdx) {
        if (hovElementIdx > scrollTopElementIdx + 4)
            scrollDown(hovElementIdx);
        if (hovElementIdx - scrollTopElementIdx >= 0)
            return;
        setScrollTopElementIdx(hovElementIdx);
    }
    function scrollDown(hovElementIdx) {
        if (hovElementIdx < scrollTopElementIdx)
            scrollUp(hovElementIdx);
        if (hovElementIdx - scrollTopElementIdx < 4)
            return;
        setScrollTopElementIdx(hovElementIdx - 4);
    }
    function supportKeyboardNavigation(event) {
        var _a, _b;
        event.preventDefault();
        switch (event.keyCode) {
            case Key.ArrowDown: {
                var hovElementIdx = (hoveredElementIdx + 1) % props.options.length;
                setHoveredElementIdx(hovElementIdx);
                scrollDown(hovElementIdx);
                break;
            }
            case Key.ArrowUp: {
                var hovElementIdx = (props.options.length + hoveredElementIdx - 1) % props.options.length;
                setHoveredElementIdx(hovElementIdx);
                scrollUp(hovElementIdx);
                break;
            }
            case Key.Enter:
            case Key.Space:
                if (!isOpen) {
                    setHoveredElementIdx(0);
                    openDropdown();
                    return;
                }
                props.setSelected(((_b = (_a = props.options) === null || _a === void 0 ? void 0 : _a[hoveredElementIdx]) === null || _b === void 0 ? void 0 : _b.value) || "");
                setOpen(false);
                break;
            case Key.Escape:
                setOpen(false);
                break;
            default:
        }
    }
    return (React.createElement("label", { className: cn(styles.select, props.className), onKeyDown: supportKeyboardNavigation, ref: labelRef },
        React.createElement("span", { className: styles.visuallyHidden }, props.label),
        React.createElement("div", { className: commonFormStyles.inputWrapper },
            React.createElement("select", { id: props.id, name: props.name, className: cn(styles.defaultText, styles.selectNative), value: props.selected, onChange: function (event) { return props.setSelected(event.target.value); }, onFocus: function () {
                    setWasFocused(true);
                    setFocused(true);
                }, onBlur: function () { return setFocused(false); } },
                React.createElement("option", { disabled: true, value: "" }, props.label),
                props.options.map(function (option) { return (React.createElement("option", { value: option.value, key: option.value }, option.label)); })),
            React.createElement("div", { className: styles.selectCustom },
                React.createElement(SelectOption, { label: props.iconOnlyTrigger ? "" : (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label) || props.label, preIcon: props.iconOnlyTrigger ? undefined : selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.icon, onClick: function (e) {
                        isOpen ? setOpen(false) : openDropdown();
                        setWasFocused(true);
                        e.currentTarget.blur();
                    }, postIcon: React.createElement(FAIcon, { icon: props.customTriggerIcon ||
                            (isOpen ? "chevron-up" : "chevron-down") }), className: cn(styles.customTrigger, "selected-value") }),
                React.createElement("div", { className: cn(styles.customOptionsWrapper, isOpen && styles.isActive), "aria-expanded": isOpen, ref: optionsRef }, props.options.map(function (option, idx) { return (React.createElement(SelectOption, { key: option.value, label: option.label, preIcon: option.icon, isHovered: hoveredElementIdx === idx, onMouseEnter: function () { return setHoveredElementIdx(idx); }, onMouseLeave: function () { return setHoveredElementIdx(-1); }, onClick: function () {
                        props.setSelected(option.value);
                        setOpen(false);
                    } })); })),
                React.createElement("div", { className: cn(styles.underlinePrimary, isFocused && styles.underlinePrimaryFocused) })))));
}
function SelectOption(props) {
    var styles = useStyles();
    return (React.createElement("button", { type: "button", className: cn(styles.customOption, props.isHovered && styles.isHover, props.className, "select-option"), onClick: props.onClick, onMouseEnter: props.onMouseEnter, onMouseLeave: props.onMouseLeave },
        props.preIcon && (React.createElement(OptionIcon, { icon: props.preIcon, className: styles.customOptionPreIconWrapper })),
        React.createElement("div", { className: cn(styles.customOptionLabel, props.postIcon && props.label && styles.customOptionLabelMargin) }, props.label || "\u00A0"),
        props.postIcon && (React.createElement(OptionIcon, { icon: props.postIcon, className: styles.customOptionPostIconWrapper }))));
}
function OptionIcon(props) {
    var styles = useStyles();
    return (React.createElement("div", { className: cn(styles.customOptionIconWrapper, props.className) }, typeof props.icon === "string" ? (React.createElement(FAIcon, { icon: props.icon })) : (props.icon)));
}
