var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { findIconDefinition, library, } from "@fortawesome/fontawesome-svg-core";
import { fa500px } from "@fortawesome/free-brands-svg-icons/fa500px";
import { faAccessibleIcon } from "@fortawesome/free-brands-svg-icons/faAccessibleIcon";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faPaypal } from "@fortawesome/free-brands-svg-icons/faPaypal";
import { faArrowToBottom } from "@fortawesome/pro-light-svg-icons/faArrowToBottom";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons/faChevronUp";
import { faComments } from "@fortawesome/pro-light-svg-icons/faComments";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons/faExternalLink";
import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import { faImages } from "@fortawesome/pro-light-svg-icons/faImages";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons/faLongArrowRight";
import { faMailbox } from "@fortawesome/pro-light-svg-icons/faMailbox";
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus";
import { faPhone } from "@fortawesome/pro-light-svg-icons/faPhone";
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import { faSearchPlus } from "@fortawesome/pro-light-svg-icons/faSearchPlus";
import { faSignIn } from "@fortawesome/pro-light-svg-icons/faSignIn";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
library.add(fa500px, faAccessibleIcon, faArrowToBottom, faChevronDown, faChevronUp, faComments, faEnvelope, faExternalLink, faGlobe, faImages, faLinkedin, faLongArrowRight, faMailbox, faPaypal, faPhone, faTimes, faSignIn, faSearchPlus, faInfoCircle, faSearch, fas, faMinus);
export function FAIcon(props) {
    var _a;
    var iconName = (_a = props.icon) === null || _a === void 0 ? void 0 : _a.toString();
    var _b = useState(false), iconLoading = _b[0], setIconLoading = _b[1];
    var _c = useState(null), importFunction = _c[0], setImportFunction = _c[1];
    var _d = useState(null), importIconFunction = _d[0], setImportIconFunction = _d[1];
    useEffect(function () {
        var mounted = true;
        import(/* webpackChunkName: "zzz.faImport" */ "./FAIconImports").then(function (exports) { return mounted && setImportFunction(function () { return exports.importChunkByName; }); });
        return function () {
            mounted = false;
        };
    }, []);
    useEffect(function () {
        var mounted = true;
        if (importIconFunction) {
            importIconFunction()
                .then(function (icons) { return library.add.apply(library, icons); })
                .finally(function () { return mounted && setIconLoading(false); });
        }
        return function () {
            mounted = false;
        };
    }, [importIconFunction]);
    if (typeof props.icon !== "string")
        return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: props.icon }));
    if (!iconName)
        return null;
    var iconDefinition = ["fal", "fab"]
        .map(function (prefix) {
        return findIconDefinition({
            prefix: prefix,
            iconName: iconName,
        });
    })
        .find(Boolean);
    if (!iconDefinition && !iconLoading) {
        if (!importFunction) {
            return null;
        }
        var importIconFunction_1 = importFunction(iconName);
        if (!importIconFunction_1) {
            console.error("Cannot find icon: ".concat(props.icon));
            return null;
        }
        setIconLoading(true);
        setImportIconFunction(function () { return importIconFunction_1; });
        return null;
    }
    if (iconLoading)
        return null;
    if (!iconDefinition) {
        console.error("Cannot find icon: ".concat(props.icon));
        return null;
    }
    return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: iconDefinition }));
}
export var FAIconStyles = function (styles) { return ({
    "svg.svg-inline--fa&": styles,
}); };
