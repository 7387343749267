var RemNumber = /** @class */ (function () {
    function RemNumber(value) {
        this.value = value;
    }
    RemNumber.prototype.valueOf = function () {
        return this.value;
    };
    RemNumber.prototype.add = function (b) {
        return new RemNumber(this.valueOf() + b.valueOf());
    };
    RemNumber.prototype.minus = function (b) {
        return new RemNumber(this.valueOf() - b.valueOf());
    };
    RemNumber.prototype.times = function (b) {
        return new RemNumber(this.valueOf() * b.valueOf());
    };
    RemNumber.prototype.divide = function (b) {
        return new RemNumber(this.valueOf() / b.valueOf());
    };
    RemNumber.prototype.toString = function () {
        return "".concat(this.valueOf(), "rem");
    };
    return RemNumber;
}());
export { RemNumber };
export var spacings = {
    xxs: new RemNumber(0.25),
    xs: new RemNumber(0.5),
    s: new RemNumber(1),
    sam: new RemNumber(1.5),
    m: new RemNumber(2),
    l: new RemNumber(2.5),
    xl: new RemNumber(3),
    xxl: new RemNumber(4),
};
